import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Alert } from 'react-bootstrap';
import { Field } from '../../../../components/Form';
import { constants, actions } from '../../../../redux/windshieldRepairabilityProfiles';
import { Loader, ConfirmationModal, Checkbox } from '../../../../components';
import { authenticationService } from '../../../../services';
import { constants as sessionConstants } from '../../../../redux/session';
import WindshieldRepairabilityProfileAreas from './WindshieldRepairabilityProfileAreas';
import { makeDelete, makePatch } from '../../../../api/ajax';

const WindshieldRepairabilityProfile = ({ windshieldRepairabilityProfile }) => {
  const [
    windshieldSafetyAreaFromCornersInCm,
    setWindshieldSafetyAreaFromCornersInCm
  ] = useState(windshieldRepairabilityProfile.windshieldSafetyAreaFromCornersInCm);
  const [
    safetyAreaAroundTheChipInCm,
    setSafetyAreaAroundTheChipInCm
  ] = useState(windshieldRepairabilityProfile.safetyAreaAroundTheChipInCm);
  const [driverFieldOfView, setDriverFieldOfView] = useState(windshieldRepairabilityProfile.driverFieldOfView);
  const [humanReviewEnabled, setHumanReviewEnabled] = useState(windshieldRepairabilityProfile.humanReviewEnabled);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [hasAdasSystems, setHasAdasSystems] = useState(windshieldRepairabilityProfile.adasFieldOfViewMask?.length > 0);

  const [driverFieldOfViewMask, setDriverFieldOfViewMask] = useState(windshieldRepairabilityProfile.driverFieldOfViewMask);
  const [adasFieldOfViewMask, setAdasFieldOfViewMask] = useState(windshieldRepairabilityProfile.adasFieldOfViewMask);

  const dispatch = useDispatch();
  const { hasPermission } = authenticationService;

  const {
    DELETE_WINDSHIELD_REPAIRABILITY_PROFILE, EDIT_WINDSHIELD_REPAIRABILITY_PROFILE
  } = sessionConstants.PERMISSION_TYPES;

  const deleteWindshieldRepairabilityProfile = () => {
    makeDelete(`windshieldRepairProfiles/${windshieldRepairabilityProfile.id}`).subscribe(() => {
      dispatch(actions.deleteWindshieldRepairabilityProfileSuccess(windshieldRepairabilityProfile.id));
    });
  };

  const handleSubmit = (formValues, setSubmitting, setStatus, hasPermission) => {
    if (!hasPermission(EDIT_WINDSHIELD_REPAIRABILITY_PROFILE)) return;
    const updatedWindshieldRepairabilityProfile = {
      ...formValues,
      // eslint-disable-next-line radix
      driverFieldOfView: parseInt(driverFieldOfView),
      humanReviewEnabled,
      driverFieldOfViewMask,
      adasFieldOfViewMask
    };
    makePatch(`windshieldRepairProfiles/${windshieldRepairabilityProfile.id}`, updatedWindshieldRepairabilityProfile)
      .subscribe(() => {
        dispatch(actions.editWindshieldRepairabilityProfileSiccess(updatedWindshieldRepairabilityProfile));
        setStatus(`Changes saved to '${updatedWindshieldRepairabilityProfile.name}'`);
        setSubmitting(false);
      });
  };

  return (
    <div className="d-flex">
      <div className="d-flex flex-column">
        <WindshieldRepairabilityProfileAreas
          repairAreaCm={windshieldSafetyAreaFromCornersInCm}
          safetyAreaAroundTheChipInCm={safetyAreaAroundTheChipInCm}
          driverFieldOfViewMask={driverFieldOfViewMask}
          adasFieldOfViewMask={adasFieldOfViewMask}
          setDriverFieldOfViewMask={setDriverFieldOfViewMask}
          setAdasFieldOfViewMask={setAdasFieldOfViewMask}
          driverFieldOfView={Number(driverFieldOfView)}
        />
        <div className="d-flex justify-content-center">
          <span className="mt-2">To change the size of the areas, click on them</span>
        </div>
      </div>
      <div className="ml-4 w-100">
        <h5>Settings</h5>
        <Formik
          initialValues={{
            name: windshieldRepairabilityProfile.name,
            windshieldSafetyAreaFromCornersInCm: windshieldRepairabilityProfile.windshieldSafetyAreaFromCornersInCm,
            safetyAreaAroundTheChipInCm: windshieldRepairabilityProfile.safetyAreaAroundTheChipInCm,
            maximumRepairableDamages: windshieldRepairabilityProfile.maximumRepairableDamages,
            hasAdasSystems,
            humanReviewEnabled: windshieldRepairabilityProfile.humanReviewEnabled
          }}
          onSubmit={(formValues, { setSubmitting, setStatus }) => handleSubmit(formValues, setSubmitting, setStatus, hasPermission)}
          enableReinitialize
          validationSchema={
            Yup.object().shape({
              name: Yup.string()
                .required('Name is required')
                .max(50, 'Name must not be longer than 50 characters')
                .min(5, 'Name must be at least 5 characters'),
              windshieldSafetyAreaFromCornersInCm: Yup.number()
                .required('Safety area is required')
                .max(30, 'Safety area from corners must not exceed 30cm')
                .min(0, 'Safety area cannot be negative'),
              safetyAreaAroundTheChipInCm: Yup.number()
                .required('Safety area around chips is required')
                .max(30, 'Safety area around chips must not exceed 30cm')
                .min(0, 'Safety area around chips cannot be negative'),
              maximumRepairableDamages: Yup.number()
                .required('Maximum repairable damages is required')
                .max(20, 'Maximum repairable damages must not exceed 20')
                .min(0, 'Maximum repairable damages cannot be negative'),
            })
          }
        >
          {
            ({ errors, touched, isSubmitting, status, setStatus, handleChange }) => (
              <Form>
                <div className="row">
                  <div className="col-10 col-sm-12">
                    {status && (
                      <Alert variant="success" dismissible onClick={() => setStatus(null)}>
                        {status}
                      </Alert>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 col-sm-12">
                    <Field
                      min="5"
                      max="50"
                      type="text"
                      name="name"
                      label="Name"
                      touched={touched.name}
                      errors={errors.name}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 col-sm-12">
                    <Field
                      type="number"
                      min="0"
                      max="30"
                      name="windshieldSafetyAreaFromCornersInCm"
                      label="Windshield safety area from corners in cm"
                      touched={touched.windshieldSafetyAreaFromCornersInCm}
                      errors={errors.windshieldSafetyAreaFromCornersInCm}
                      onChange={(e) => {
                        if (!isNaN(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) <= 30) {
                          setWindshieldSafetyAreaFromCornersInCm(Number(e.target.value));
                        }
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 col-sm-12">
                    <Field
                      type="number"
                      min="0"
                      max="30"
                      name="safetyAreaAroundTheChipInCm"
                      label="Safety area around the chip in cm"
                      touched={touched.safetyAreaAroundTheChipInCm}
                      errors={errors.safetyAreaAroundTheChipInCm}
                      onChange={(e) => {
                        if (!isNaN(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) <= 30) {
                          setSafetyAreaAroundTheChipInCm(Number(e.target.value));
                        }
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 col-sm-12">
                    <Field
                      type="number"
                      min="0"
                      max="20"
                      name="maximumRepairableDamages"
                      label="Maximum repairable damages"
                      touched={touched.maximumRepairableDamages}
                      errors={errors.maximumRepairableDamages}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 col-sm-12">
                    <label className="mb-0 mt-1" htmlFor="driverFieldOfView">
                      Driver field of view
                    </label>
                    <select
                      className="form-control"
                      value={driverFieldOfView}
                      onChange={(event) => {
                        if (Number(event.target.value) === constants.DRIVER_FIELD_OF_VIEW.RIGHT) {
                          setDriverFieldOfViewMask(constants.DEFAULT_DRIVER_FOV_RIGHT);
                        } else if (Number(event.target.value) === constants.DRIVER_FIELD_OF_VIEW.CENTER) {
                          setDriverFieldOfViewMask(constants.DEFAULT_DRIVER_FOV_CENTER);
                        } else if (Number(event.target.value) === constants.DRIVER_FIELD_OF_VIEW.LEFT) {
                          setDriverFieldOfViewMask(constants.DEFAULT_DRIVER_FOV_LEFT);
                        } else {
                          setDriverFieldOfViewMask([]);
                        }

                        setDriverFieldOfView(event.target.value);
                      }}
                      name="driverFieldOfView"
                      id="driverFieldOfView"
                    >
                      <option value={constants.DRIVER_FIELD_OF_VIEW.RIGHT}>
                        {constants.DRIVER_FIELD_OF_VIEW_NAME[constants.DRIVER_FIELD_OF_VIEW.LEFT]}
                      </option>
                      <option value={constants.DRIVER_FIELD_OF_VIEW.CENTER}>
                        {constants.DRIVER_FIELD_OF_VIEW_NAME[constants.DRIVER_FIELD_OF_VIEW.CENTER]}
                      </option>
                      <option value={constants.DRIVER_FIELD_OF_VIEW.LEFT}>
                        {constants.DRIVER_FIELD_OF_VIEW_NAME[constants.DRIVER_FIELD_OF_VIEW.RIGHT]}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 col-sm-12">
                    <div className="mt-3">
                      <Checkbox
                        id="hasAdasSystems"
                        label="ADAS systems"
                        name="hasAdasSystems"
                        checked={hasAdasSystems}
                        onClickHandler={() => {
                          if (hasAdasSystems) {
                            setAdasFieldOfViewMask([]);
                          } else {
                            setAdasFieldOfViewMask(constants.DEFAULT_ADAS_FOV);
                          }
                          setHasAdasSystems(!hasAdasSystems);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 col-sm-12">
                    <div className="mt-2">
                      <Checkbox
                        id="humanReviewEnabled"
                        label="Damage mismatch review (human review)"
                        name="humanReviewEnabled"
                        checked={humanReviewEnabled}
                        onClickHandler={() => setHumanReviewEnabled(!humanReviewEnabled)}
                      />
                    </div>
                  </div>
                </div>
                {
                  humanReviewEnabled && (
                    <div className="row">
                      <div className="col-10 col-sm-12">
                        <span className="text-danger mt-1">NB! This service increases the price of the completed scan by 0.89€!</span>
                      </div>
                    </div>
                  )
                }
                <div className="row">
                  <div className="col-sm-12 text-right mt-2">
                    {hasPermission(DELETE_WINDSHIELD_REPAIRABILITY_PROFILE) && (
                      <button
                        type="button"
                        className="button btn"
                        data-variation="light-blue"
                        onClick={() => setShowConfirmDeleteModal(true)}
                      >
                        Remove
                      </button>
                    )}
                    {hasPermission(EDIT_WINDSHIELD_REPAIRABILITY_PROFILE) && (
                      <button type="submit" className="button btn btn-primary ml-2" disabled={isSubmitting}>
                        {isSubmitting
                          ? (<Loader size="tiny" isRelative noDelay white />)
                          : 'Save'}
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )
          }
        </Formik>
        <ConfirmationModal
          showModal={showConfirmDeleteModal}
          heading="Delete windshield repairability profile"
          message="Are you sure you wish to delete this windshield repairability profile?"
          onConfirm={() => {
            deleteWindshieldRepairabilityProfile();
            setShowConfirmDeleteModal(false);
          }}
          onCancel={() => setShowConfirmDeleteModal(false)}
          style={{ top: '6.25rem' }}
        />
      </div>
    </div>
  );
};

WindshieldRepairabilityProfile.propTypes = {
  windshieldRepairabilityProfile: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    windshieldSafetyAreaFromCornersInCm: PropTypes.number,
    safetyAreaAroundTheChipInCm: PropTypes.number,
    maximumRepairableDamages: PropTypes.number,
    driverFieldOfView: PropTypes.number,
    humanReviewEnabled: PropTypes.bool,
    adasFieldOfViewMask: PropTypes.array,
    driverFieldOfViewMask: PropTypes.array
  }),
};

export default WindshieldRepairabilityProfile;
