const NAME = 'workflows';

const VEHICLE_TYPES = {
  CAR: 1,
  MOTORCYCLE: 2,
  TRUCK: 3,
  TRAILER: 4,
  BUS: 7
};

const VEHICLE_TYPE_NAME = {
  [VEHICLE_TYPES.CAR]: 'Car',
  [VEHICLE_TYPES.MOTORCYCLE]: 'Motorcycle',
  [VEHICLE_TYPES.TRUCK]: 'Truck',
  [VEHICLE_TYPES.TRAILER]: 'Trailer',
  [VEHICLE_TYPES.BUS]: 'Bus'
};

export default {
  NAME,
  VEHICLE_TYPES,
  VEHICLE_TYPE_NAME
};
