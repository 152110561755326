import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Table, Text, Toggle, Checkbox } from '../../../../../components';
import { selectors, actions } from '../../../../../redux/customImageTypes';
import { makeGet } from '../../../../../api/ajax';

const CustomImageTypeSelection = ({ imageSubTypes, setFieldValue }) => {
  const dispatch = useDispatch();
  const customImageTypes = useSelector(selectors.selectCustomImageTypes);

  useEffect(() => {
    if (!customImageTypes) {
      makeGet('customImageTypes?pageSize=150').subscribe(({ response }) => {
        dispatch(actions.loadCustomImageTypesSuccess(response));
      });
    }
  }, [customImageTypes, dispatch]);

  if (customImageTypes === null) return null;

  return (
    <>
      <Table
        header={
          <>
            <div className="col-sm-1 row-text">
              <Text size={2} weight="bold" inline>Order</Text>
            </div>
            <div className="col-sm-4 row-text">
              <Text size={2} weight="bold" inline>Name</Text>
            </div>
            <div className="col-sm-1 row-text">
              <Text size={2} inline>Mandatory</Text>
            </div>
            <div className="col-sm-2 row-text">
              <Text size={2} inline>Comments allowed</Text>
            </div>
            <div className="col-sm-2 row-text">
              <Text size={2} inline>Ask comment automatically</Text>
            </div>
            <div className="col-sm-2 row-text">
              <Text size={2} inline>Show comment in report</Text>
            </div>
          </>
        }
        rows={customImageTypes.results.map((customImageType) => ({
          id: customImageType.id,
          name: customImageType.name,
          deleted: false,
          row: <CustomImageTypeRow
            key={customImageType.id}
            customImageType={customImageType}
            imageSubTypes={imageSubTypes}
            setFieldValue={setFieldValue}
          />
        }))}
      />
    </>
  );
};

CustomImageTypeSelection.propTypes = {
  imageSubTypes: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

const CustomImageTypeRow = ({ customImageType, imageSubTypes, setFieldValue }) => {
  const { name } = customImageType;
  const imageSubType = imageSubTypes.find((imageSubType) => imageSubType.customImageSubTypeId === customImageType.id);
  const imageSubTypeEnabled = imageSubType !== undefined;
  const order = imageSubTypeEnabled
    ? imageSubTypes.indexOf(imageSubType) + 1
    : '-';

  const optional = imageSubType?.optional !== undefined
    ? imageSubType.optional
    : true;

  const commentsEnabled = imageSubType?.smartScanCommentSettings?.enabled !== undefined
    ? imageSubType.smartScanCommentSettings.enabled
    : false;

  const commentsAutomaticPopup = imageSubType?.smartScanCommentSettings?.automaticPopup !== undefined
    ? imageSubType.smartScanCommentSettings.automaticPopup
    : false;

  const commentsVisibleInReport = imageSubType?.smartScanCommentSettings?.visibleInReport !== undefined
    ? imageSubType.smartScanCommentSettings.visibleInReport
    : false;

  const imageSubTypeSettings = {
    customImageSubTypeId: customImageType.id,
    instructions: [],
    smartScanCommentSettings: {
      enabled: false,
      automaticPopup: false,
      visibleInReport: false
    }
  };

  return (
    <>
      <div className="col-sm-1 row-text d-flex justify-content-center">
        <Text size={2} inline>
          { order }
        </Text>
      </div>
      <div className="col-sm-4 row-text row-toggle">
        <Toggle
          on={imageSubTypeEnabled}
          toggleClick={() => {
            setFieldValue(
              'imageSubTypes',
              !imageSubTypeEnabled
                ? [...imageSubTypes, imageSubTypeSettings]
                : imageSubTypes.filter((c) => c.customImageSubTypeId !== customImageType.id)
            );
          }}
        />
        <div className="ml-2">
          <Text size={2} inline>
            { name }
          </Text>
        </div>
      </div>
      <div className="col-sm-1 row-toggle">
        <Toggle
          on={!optional}
          toggleClick={() => {
            setFieldValue('imageSubTypes', imageSubTypes.map((imageSubType) => {
              if (imageSubType.customImageSubTypeId === customImageType.id) {
                imageSubType.optional = !optional;
              }
              return imageSubType;
            }));
          }}
          disabled={!imageSubTypeEnabled}
        />
      </div>
      <div className="col-sm-2 row-toggle">
        <Toggle
          on={commentsEnabled}
          toggleClick={() => {
            setFieldValue('imageSubTypes', imageSubTypes.map((imageSubType) => {
              if (imageSubType.customImageSubTypeId === customImageType.id) {
                imageSubType.smartScanCommentSettings.enabled = !commentsEnabled;
                if (!commentsEnabled === false) {
                  imageSubType.smartScanCommentSettings.automaticPopup = false;
                  imageSubType.smartScanCommentSettings.visibleInReport = false;
                }
              }
              return imageSubType;
            }));
          }}
          disabled={!imageSubTypeEnabled}
        />
      </div>
      <div className="col-sm-2 row-checkbox">
        <Checkbox
          id={`${customImageType.id}-comment-automatic`}
          name={`${customImageType.id}-comment-automatic`}
          checked={commentsAutomaticPopup}
          onClickHandler={() => {
            setFieldValue('imageSubTypes', imageSubTypes.map((imageSubType) => {
              if (imageSubType.customImageSubTypeId === customImageType.id) {
                imageSubType.smartScanCommentSettings.automaticPopup = !commentsAutomaticPopup;
              }
              return imageSubType;
            }));
          }}
          disabled={!commentsEnabled}
        />
      </div>
      <div className="col-sm-2 row-checkbox">
        <Checkbox
          id={`${customImageType.id}-comment-visible-in-report`}
          name={`${customImageType.id}-comment-visible-in-report`}
          checked={commentsVisibleInReport}
          onClickHandler={() => {
            setFieldValue('imageSubTypes', imageSubTypes.map((imageSubType) => {
              if (imageSubType.customImageSubTypeId === customImageType.id) {
                imageSubType.smartScanCommentSettings.visibleInReport = !commentsVisibleInReport;
              }
              return imageSubType;
            }));
          }}
          disabled={!commentsEnabled}
        />
      </div>
    </>
  );
};

CustomImageTypeRow.propTypes = {
  customImageType: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  imageSubTypes: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default CustomImageTypeSelection;
