import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';

import { Modal, onHideHandler, renderShowConfirmationModal, Checkbox } from '../../../../components';
import { actions } from '../../../../redux/customerConfigurations';
import { makePatch } from '../../../../api/ajax';

const EnabledLanguagesModal = ({ customerConfiguration, show, onModalClose, languages }) => {
  const dispatch = useDispatch();

  const [enabledLanguages, setEnabledLanguages] = useState(customerConfiguration.enabledLanguages);
  const [showSuccessMessage, setShowSuccessMessage] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    setEnabledLanguages(customerConfiguration.enabledLanguages);
  }, [customerConfiguration.enabledLanguages]);

  const handleSave = () => {
    setShowSuccessMessage(null);
    makePatch('customerConfigurations/current', {
      enabledLanguages
    }).subscribe(
      ({ response }) => {
        dispatch(actions.loadCurrentCustomerConfigurationSuccess(response));
        setShowSuccessMessage('Enabled languages has been changed!');
        setHasUnsavedChanges(false);
      },
      () => {
        setHasUnsavedChanges(false);
      }
    );
  };

  const hideDiscardConfirmation = () => {
    hideModal();
    setShowConfirmModal(false);
  };

  const hideModal = () => {
    setShowSuccessMessage(null);
    onModalClose();
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, hideDiscardConfirmation)}
      <Modal
        headerText="Enabled languages"
        size="md"
        backdrop="static"
        show={show}
        onHide={() => onHideHandler(hasUnsavedChanges, hideModal, setShowConfirmModal)}
      >
        <Modal.Body>
          <div className="row">
            <div className="col">
              {showSuccessMessage && (
                <Alert variant="success" dismissible onClick={() => setShowSuccessMessage(null)}>
                  {showSuccessMessage}
                </Alert>
              )}
            </div>
          </div>
          {
            languages.map((language) => (
              <div className="row" key={language.id}>
                <div className="col">
                  <Checkbox
                    className="ml-4"
                    id={language.id}
                    label={language.name}
                    name="languages"
                    checked={enabledLanguages.includes(language.id)}
                    onClickHandler={() => {
                      const newEnabledLanguages = enabledLanguages.includes(language.id)
                        ? enabledLanguages.filter((lang) => lang !== language.id)
                        : [...enabledLanguages, language.id];
                      if (newEnabledLanguages.length === 0) return;
                      setEnabledLanguages(newEnabledLanguages);
                      setHasUnsavedChanges(true);
                    }}
                  />
                </div>
              </div>
            ))
          }
          <div className="row mt-4">
            <div className="col-12">
              <button
                type="reset"
                className="btn btn-secondary mr-1"
                onClick={() => onHideHandler(hasUnsavedChanges, onModalClose, setShowConfirmModal)}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

EnabledLanguagesModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  customerConfiguration: PropTypes.object,
  languages: PropTypes.array
};

export default EnabledLanguagesModal;
