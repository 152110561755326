import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { FilterLabel } from '../../../../components';
import { constants as photoSeriesConstants } from '../../../../redux/photoSeries';
import { constants as imagesConstants } from '../../../../redux/images';
import { selectors as workflowsSelectors, actions as workflowActions } from '../../../../redux/workflows';
import { useVehicleFilterContext } from '../../Vehicles';
import { formatDate } from '../../../../utils/date';

import { makeGet } from '../../../../api/ajax';

import { getSharedCustomers, getSharedWorkflows } from '../VehiclesFilter/containers/VehiclesFilterModal';

const VehiclesFilterTags = () => {
  const [vehiclesFilter, setVehiclesFilter] = useVehicleFilterContext();
  const filters = [];

  const dispatch = useDispatch();

  const workflows = useSelector(workflowsSelectors.selectWorkFlows);
  const sharedWorkflows = useSelector(workflowsSelectors.selectSharedWorkflows);

  const {
    searchPhrase,
    completedOnOrAfter,
    completedOnOrBefore,
    workflowIds,
    feedbackRatings,
    photoSeriesStatuses,
    photoSeriesQualityRatings,
    foundImageErrors,
    externalId,
    hasImages,
    hasFeedbackComment,
    damages,
    hasAttemptedImages,
    sharedWorkflowIds,
    sharedCustomerIds
  } = vehiclesFilter;

  useEffect(() => {
    if (!sharedWorkflows && (sharedWorkflowIds || sharedCustomerIds)) {
      makeGet('workflows/currentCustomer/shared').subscribe(({ response }) => {
        dispatch(workflowActions.loadSharedWorkflowsSuccess(response));
      });
    }
  }, [sharedWorkflows, sharedWorkflowIds, sharedCustomerIds, dispatch]);

  const removeFilter = (filter) => {
    const { id, key, isMultiple } = filter;

    if (!isMultiple) {
      delete vehiclesFilter[key];

      setVehiclesFilter({ ...vehiclesFilter, page: 1 });
    } else {
      const filterArray = vehiclesFilter[key];
      const filter = {
        [key]: filterArray.filter((filterValue) => filterValue !== id)
      };

      setVehiclesFilter({ ...vehiclesFilter, ...filter, page: 1 });
    }
  };

  if (searchPhrase) {
    filters.push({
      labelText: 'Search vehicle',
      values: [
        {
          id: 'searchPhrase',
          key: 'searchPhrase',
          value: searchPhrase,
        },
      ],
    });
  }

  if (completedOnOrAfter) {
    filters.push({
      labelText: 'Completed date start',
      values: [
        {
          id: 'completedOnOrAfter',
          key: 'completedOnOrAfter',
          value: formatDate(completedOnOrAfter),
        },
      ],
    });
  }

  if (completedOnOrBefore) {
    filters.push({
      labelText: 'Completed date end',
      values: [
        {
          id: 'completedOnOrBefore',
          key: 'completedOnOrBefore',
          value: formatDate(completedOnOrBefore),
        },
      ],
    });
  }

  if (sharedWorkflowIds && sharedWorkflows) {
    filters.push({
      labelText: 'Shared workflows',
      values: sharedWorkflowIds.map((workflowId) => ({
        id: workflowId,
        key: 'sharedWorkflowIds',
        value: getSharedWorkflows(sharedWorkflows).find(({ id }) => id === workflowId).name,
        isMultiple: true,
      })),
    });
  }

  if (sharedCustomerIds && sharedWorkflows) {
    filters.push({
      labelText: 'Tags',
      values: sharedCustomerIds.map((customerId) => ({
        id: customerId,
        key: 'sharedCustomerIds',
        value: getSharedCustomers(sharedWorkflows).find(({ id }) => id === customerId).name,
        isMultiple: true,
      })),
    });
  }

  if (workflowIds) {
    filters.push({
      labelText: 'Workflow',
      values: workflowIds.map((workflowId) => ({
        id: workflowId,
        key: 'workflowIds',
        value: workflows && workflows[workflowId].name,
        isMultiple: true,
      })),
    });
  }

  if (feedbackRatings) {
    filters.push({
      labelText: 'Feedback rating',
      values: feedbackRatings.map((rating) => ({
        id: rating,
        key: 'feedbackRatings',
        value: rating.toString(),
        isMultiple: true,
      })),
    });
  }

  if (photoSeriesStatuses) {
    filters.push({
      labelText: 'Photoseries status',
      values: photoSeriesStatuses.map((status) => ({
        id: status,
        key: 'photoSeriesStatuses',
        value: photoSeriesConstants.STATUS_NAME[status],
        isMultiple: true,
      })),
    });
  }

  if (photoSeriesQualityRatings) {
    filters.push({
      labelText: 'Report status',
      values: photoSeriesQualityRatings.map((qualityRating) => ({
        id: qualityRating,
        key: 'photoSeriesQualityRatings',
        value: photoSeriesConstants.QUALITY_RATING_NAME[qualityRating],
        isMultiple: true,
      })),
    });
  }

  if (foundImageErrors) {
    filters.push({
      labelText: 'Found errors',
      values: foundImageErrors.map((imageError) => ({
        id: imageError,
        key: 'foundImageErrors',
        value: imagesConstants.IMAGE_VERIFICATION_ERROR_DISPLAY_TEXT[imageError],
        isMultiple: true,
      })),
    });
  }

  if (externalId) {
    filters.push({
      labelText: 'External ID',
      values: [
        {
          id: 'externalId',
          key: 'externalId',
          value: externalId,
        },
      ],
    });
  }

  if (hasImages === true || hasImages === false) {
    filters.push({
      labelText: 'Has images',
      values: [
        {
          id: 'hasImages',
          key: 'hasImages',
          value: hasImages === true ? 'Yes' : 'No',
        },
      ],
    });
  }

  if (hasAttemptedImages === true || hasAttemptedImages === false) {
    filters.push({
      labelText: 'Has attempted images',
      values: [
        {
          id: 'hasAttemptedImages',
          key: 'hasAttemptedImages',
          value: hasAttemptedImages === true ? 'Yes' : 'No',
        },
      ],
    });
  }

  if (hasFeedbackComment === true || hasFeedbackComment === false) {
    filters.push({
      labelText: 'Has feedback comment',
      values: [
        {
          id: 'hasFeedbackComment',
          key: 'hasFeedbackComment',
          value: hasFeedbackComment === true ? 'Yes' : 'No',
        },
      ],
    });
  }

  if (damages) {
    filters.push({
      labelText: 'Damages',
      values: damages.map((damage) => ({
        id: damage,
        key: 'damages',
        value: photoSeriesConstants.DAMAGES_NAME[damage],
        isMultiple: true
      }))
    });
  }

  if (_.isEmpty(filters)) return null;

  return (
    <div className="d-flex flex-wrap">
      {filters.map((filter) => (
        <FilterLabel key={filter.labelText} {...filter} onDismiss={(value) => removeFilter(value)} />
      ))}
    </div>
  );
};

export default VehiclesFilterTags;
