import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { MenuPage } from '../../layouts';
import { routes } from '../../routes/Routes';
import { makeGet, makeGetFile } from '../../api/ajax';
import { authenticationService } from '../../services';
import { useSubscriptionLimit, FEATURES } from '../../hooks';
import { constants as sessionConstants } from '../../redux/session';
import { Button, Badge, PageTitle, InfoModal } from '../../components';
import { constants as subscriptionConstants } from '../../redux/subscriptions';
import { actions as workflowsActions, selectors as workflowsSelectors } from '../../redux/workflows';
import { actions as customerConfigurationsActions, selectors as customerConfigurationsSelectors } from '../../redux/customerConfigurations';

import WorkflowSettings from './containers/WorkflowSettings/WorfklowSettings';
import WorkflowModal from './containers/WorkflowSettings/containers/WorkflowModal';

const Workflows = () => {
  const [isLoadingWorkflows, setIsLoadingWorkflows] = useState(false);

  const dispatch = useDispatch();
  const customerConfiguration = useSelector(customerConfigurationsSelectors.selectCustomerConfiguration);
  const workflows = useSelector(workflowsSelectors.selectWorkFlows);

  const [workflowModalData, setWorkflowModalData] = useState(null);

  const { isFeatureDisabled: isCreatingWorkflowsDisabled } = useSubscriptionLimit(FEATURES.customWorkflows);
  const { isFeatureDisabled: isManageImagesSubscriptionLimited } = useSubscriptionLimit(FEATURES.manageImages);

  useEffect(() => {
    if (!customerConfiguration) {
      makeGet('customerConfigurations/current').subscribe(({ response }) => {
        dispatch(customerConfigurationsActions.loadCurrentCustomerConfigurationSuccess(response));
      });

      makeGetFile('customerConfigurations/current/custom-logo').subscribe(({ response }) => {
        // When custom logo is configured, display it
        if (response && response.size > 0) {
          dispatch(customerConfigurationsActions.loadCurrentCustomerLogoSuccess(response));
        }
      });
    }
  }, [customerConfiguration, dispatch]);

  useEffect(() => {
    if (!workflows) {
      setIsLoadingWorkflows(true);

      makeGet('workflows/currentCustomer/admin?showDeleted=true').subscribe(
        ({ response }) => {
          setIsLoadingWorkflows(false);
          dispatch(workflowsActions.loadWorkflowsSuccess(response));
        },
        () => {
          setIsLoadingWorkflows(false);
        }
      );
    }
  }, [workflows, dispatch]);

  const handleAddWorkflowClick = (event) => {
    event.stopPropagation();

    setWorkflowModalData({
      id: '',
      name: '',
      reportingEmail: '',
      expectedCountryId: ''
    });
  };

  const { hasPermission } = authenticationService;
  const canEditWorkflows = hasPermission(sessionConstants.PERMISSION_TYPES.EDIT_WORKFLOWS);
  const workflowCount = workflows !== undefined ? Object.keys(_.filter(workflows, (w) => w.deletedAt === null)).length : 0;

  const workflowsMarkup = (
    <>
      <PageTitle>
        <PageTitle.Left headingText="Workflows" isLoading={isLoadingWorkflows}>
          {
            !isLoadingWorkflows && (
              <Badge className="ml-2">
                { workflowCount }
              </Badge>
            )
          }
        </PageTitle.Left>

        <PageTitle.Right>
          <Button testId="AddNewWorkflowBtn" className="btn btn-primary" onClick={handleAddWorkflowClick} disabled={isCreatingWorkflowsDisabled}>
            +  Add new
          </Button>
        </PageTitle.Right>
      </PageTitle>

      {customerConfiguration && workflows && canEditWorkflows && (
      <>
        <div className="px-1">
          {isCreatingWorkflowsDisabled && (
            <InfoModal
              type="warning"
              message={subscriptionConstants.SUBSCRIPTION_MESSAGES.cannotCreateWorkflows}
              shownModal={isCreatingWorkflowsDisabled}
            />
          )}
          {isManageImagesSubscriptionLimited && (
            <InfoModal
              type="warning"
              message={subscriptionConstants.SUBSCRIPTION_MESSAGES.cannotManageImages}
              shownModal={isManageImagesSubscriptionLimited}
            />
          )}
        </div>

        <div className="row">
          {_.map(
            _.filter(workflows, (w) => w.deletedAt === null),
            (workflow, key) => (
              <div
                className="col col-sm-12 col-md-6 col-xl-4 mt-2"
                key={key}
                disabled={isManageImagesSubscriptionLimited}
                style={{ pointerEvents: `${isManageImagesSubscriptionLimited ? 'none' : 'auto'}` }}
              >
                <WorkflowSettings workflow={workflow} editWorkflow={setWorkflowModalData} canRemove={workflowCount > 1} />
              </div>
            )
          )}
        </div>
      </>
      )}

      <WorkflowModal
        show={workflowModalData !== null}
        workflow={workflowModalData}
        onHide={() => setWorkflowModalData(null)}
        hasReachedWorkflowsLimit={isCreatingWorkflowsDisabled}
      />
    </>
  );

  return <MenuPage currentRoute={routes.workflows}>{workflowsMarkup}</MenuPage>;
};

export default Workflows;
