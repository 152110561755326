import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Form, Formik, Field as FormikField } from 'formik';
import * as Yup from 'yup';

import { authenticationService } from '../../../../../services';

import { useBeforeUnload, useUniqueIds } from '../../../../../hooks';
import { makePost, makePut, makeGet } from '../../../../../api/ajax';
import { Error, Field } from '../../../../../components/Form';
import { Modal, Text, onHideHandler, renderShowConfirmationModal } from '../../../../../components';
import { actions as workflowsActions, constants as workflowsConstants } from '../../../../../redux/workflows';
import { selectors as subsscriptionSelectors } from '../../../../../redux/subscriptions';
import { constants as sessionConstants } from '../../../../../redux/session';
import { trackEvent, EVENT_TYPES } from '../../../../../api/analytics';

import { selectors as customerConfigurationsSelectors } from '../../../../../redux/customerConfigurations';
import { selectors as countriesSelectors, actions as countriesActions } from '../../../../../redux/countries';
import {
  selectors as windshieldRepairProfileSelectors,
  actions as windshieldRepairProfileActions
} from '../../../../../redux/windshieldRepairabilityProfiles';

const { EDIT_WORKFLOW_USER_FEEDBACK_SETTINGS } = sessionConstants.PERMISSION_TYPES;

const handleSubmit = (formData, setSuccessMessage, setErrorMessage, dispatch, setHasUnsavedChanges, setSubmitting) => {
  const workflowId = formData.id;

  setSuccessMessage(null);
  setErrorMessage(null);
  setSubmitting(true);

  const handleError = (err) => {
    setHasUnsavedChanges(false);

    const { status, response: { error } } = err;

    switch (status) {
      case 400:
        setErrorMessage(error);
        break;
      default:
        setErrorMessage(null);
        break;
    }

    setSubmitting(false);
  };

  if (workflowId) {
    // Edit workflow
    makePut(`workflows/${workflowId}`, formData).subscribe(
      ({ response }) => {
        dispatch(workflowsActions.updateWorkflowSuccess(workflowId, response));

        setHasUnsavedChanges(false);
        setSuccessMessage('Workflow changes saved!');
        setSubmitting(false);

        // Track event in Analytics
        trackEvent(EVENT_TYPES.WORKFLOW_EDITED);
      },
      (err) => handleError(err)
    );
  } else {
    // Add workflow
    makePost('workflows', formData).subscribe(
      ({ response }) => {
        dispatch(workflowsActions.createWorkflowSuccess(response));

        setHasUnsavedChanges(false);
        setSuccessMessage('New workflow added!');
        setSubmitting(false);

        // Track event in Analytics
        const { id, ...data } = formData;
        trackEvent(EVENT_TYPES.WORKFLOW_ADDED, data);
      },
      (err) => handleError(err)
    );
  }
};

const WorkflowModal = ({ onHide, workflow, hasReachedWorkflowsLimit, ...props }) => {
  const { CAR, MOTORCYCLE, TRUCK, BUS, TRAILER } = workflowsConstants.VEHICLE_TYPES;
  const { show } = props;

  const { hasPermission } = authenticationService;

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [vehicleType, setVehicleType] = useState(CAR);
  const [carInputId, motorcycleInputId, truckInputId, busInputId, trailerInputId] = useUniqueIds(4);

  const [expectedCountryId, setExpectedCountryId] = useState(null);
  const [windshieldRepairProfileId, setWindshieldRepairProfileId] = useState(null);

  const countriesFromPagedResult = useSelector(countriesSelectors.selectPagedResult)?.entities;
  const countries = countriesFromPagedResult !== undefined
    ? Object.entries(countriesFromPagedResult).map((key) => key[1])
    : [];

  const enabledFeatures = useSelector(subsscriptionSelectors.selectEnabledFeatures);
  const windshieldRepairProfiles = useSelector(windshieldRepairProfileSelectors.selectWindshieldRepairabilityProfiles);

  useBeforeUnload(hasUnsavedChanges);

  const dispatch = useDispatch();
  const customerConfiguration = useSelector(customerConfigurationsSelectors.selectCustomerConfiguration);

  useEffect(() => {
    if (customerConfiguration?.expectedCountryValidationEnabled && show && countries.find(((country) => country.name === 'Estonia')) === undefined) {
      makeGet('countries?countryName=Estonia').subscribe(
        ({ response }) => dispatch(countriesActions.loadCountriesSuccess(response))
      );
    }
  }, [customerConfiguration, show, countries, dispatch]);

  useEffect(() => {
    if (workflow && workflow.expectedCountryId) {
      setExpectedCountryId(workflow.expectedCountryId);
    }
    if (workflow && workflow.windshieldRepairProfileId) {
      setWindshieldRepairProfileId(workflow.windshieldRepairProfileId);
    }
  }, [workflow]);

  useEffect(() => {
    if (!windshieldRepairProfiles) {
      makeGet('windshieldRepairProfiles?pageSize=150').subscribe(
        ({ response }) => dispatch(windshieldRepairProfileActions.loadWindshieldRepairabilityProfilesSuccess(response))
      );
    }
  }, [windshieldRepairProfiles, dispatch]);

  const hideModal = () => {
    setVehicleType(CAR);
    setSuccessMessage(null);
    setErrorMessage(null);
    setExpectedCountryId(null);
    onHide();
  };

  const displayVehicleTypes = !workflow || (workflow && !workflow.id);
  const editingWorkflow = workflow && workflow.id;

  const initialValues = {
    ...workflow,
    timeLimitValidationEnabled: editingWorkflow
      ? workflow.timeLimitValidationEnabled
      : true,
    smartScanImageDownloadEnabled: editingWorkflow
      ? workflow.smartScanImageDownloadEnabled
      : true,
    windshieldRepairReplaceDecisionEnabled: editingWorkflow
      ? workflow.windshieldRepairReplaceDecisionEnabled
      : false,
    manualDamageLabellingConfirmNoDamages: editingWorkflow
      ? workflow.manualDamageLabellingConfirmNoDamages
      : true,
    userFeedbackSettings: {
      satisfactionRatingEnabled: editingWorkflow
        ? workflow.userFeedbackSettings.satisfactionRatingEnabled
        : true,
      commentEnabled: editingWorkflow
        ? workflow.userFeedbackSettings.commentEnabled
        : true,
    },
    publicUserReturnUrl: workflow?.publicUserReturnUrl || '',
    windshieldRepairReplaceBookingUrl: workflow?.windshieldRepairReplaceBookingUrl || '',
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, () => {
        hideModal();
        setHasUnsavedChanges(false);
        setShowConfirmModal(false);
      })}

      <Modal
        size="md"
        headerText={editingWorkflow ? 'Edit workflow' : 'Add new workflow'}
        onHide={() => {
          onHideHandler(hasUnsavedChanges, hideModal, setShowConfirmModal);
        }}
        backdrop="static"
        {...props}
      >
        <Modal.Body>
          <div className="row">
            <div className="col">
              {successMessage && (
                <Alert variant="success" dismissible onClick={() => setSuccessMessage(null)}>
                  {successMessage}
                </Alert>
              )}
              {
                errorMessage && (
                  <Alert variant="danger" dismissible onClick={() => setErrorMessage(null)}>
                    {errorMessage}
                  </Alert>
                )
              }
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Workflow name is required'),
              reportingEmail: Yup.string().email('Please enter a correct email').nullable(),
            })}
            onSubmit={(formData, { setSubmitting }) => {
              handleSubmit(
                { ...formData, vehicleType, expectedCountryId, windshieldRepairProfileId },
                setSuccessMessage, setErrorMessage, dispatch, setHasUnsavedChanges, setSubmitting
              );
            }}
            validateOnChange={false}
          >
            {({ values, errors, status, touched, setFieldValue, isSubmitting }) => (
              <Form
                onChange={({ target }) => {
                  if (!hasUnsavedChanges && target.value !== initialValues[target.name]) {
                    setHasUnsavedChanges(true);
                  }
                }}
              >
                <div className="row">
                  <div className="col-10 col-sm-12">
                    <Field type="text" name="name" label="Workflow name" touched={touched.name} errors={errors.name} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-10 col-sm-12">
                    <Field
                      type="email"
                      name="reportingEmail"
                      label="Report e-mail"
                      touched={touched.reportingEmail}
                      errors={errors.reportingEmail}
                    />
                    {status && <Error>{status}</Error>}
                  </div>
                </div>

                <div className="row">
                  <div className="col-10 col-sm-12">
                    <Field
                      type="text"
                      name="publicUserReturnUrl"
                      label="Public user return url"
                      touched={touched.publicUserReturnUrl}
                      errors={errors.publicUserReturnUrl}
                    />
                    {status && <Error>{status}</Error>}
                  </div>
                </div>

                {displayVehicleTypes && (
                  <>
                    <div className="row my-2">
                      <div className="col-10 col-sm-12">
                        <Text>Instructions for</Text>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <label htmlFor={carInputId}>
                          <input
                            type="radio"
                            name="vehicleType"
                            checked={vehicleType === CAR}
                            id={carInputId}
                            onChange={() => setVehicleType(CAR)}
                          />
                          &nbsp; Car
                        </label>
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor={motorcycleInputId}>
                          <input
                            type="radio"
                            name="vehicleType"
                            checked={vehicleType === MOTORCYCLE}
                            id={motorcycleInputId}
                            onChange={() => setVehicleType(MOTORCYCLE)}
                          />
                          &nbsp; Motorcycle
                        </label>
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor={truckInputId}>
                          <input
                            type="radio"
                            name="vehicleType"
                            checked={vehicleType === TRUCK}
                            id={truckInputId}
                            onChange={() => setVehicleType(TRUCK)}
                          />
                          &nbsp; Truck
                        </label>
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor={busInputId}>
                          <input
                            type="radio"
                            name="vehicleType"
                            checked={vehicleType === BUS}
                            id={busInputId}
                            onChange={() => setVehicleType(BUS)}
                          />
                          &nbsp; Bus
                        </label>
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor={trailerInputId}>
                          <input
                            type="radio"
                            name="vehicleType"
                            checked={vehicleType === TRAILER}
                            id={trailerInputId}
                            onChange={() => setVehicleType(TRAILER)}
                          />
                          &nbsp; Trailer
                        </label>
                      </div>
                    </div>
                  </>
                )}

                <h6 className="mt-2">Windshield repair replace settings</h6>

                <div className="row">
                  <div className="col-10 col-sm-12">
                    <div className="form-check">
                      <FormikField
                        name="windshieldRepairReplaceDecisionEnabled"
                        className="form-check-input mr-2"
                        type="checkbox"
                      />
                      <label className="form-check-label" htmlFor="windshieldRepairReplaceDecisionEnabled">
                        Enabled
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-10 col-sm-12">
                    <label htmlFor="windshieldRepairProfileId" className="mb-0 mt-1">
                      Windshield repairability profile
                    </label>
                    <select
                      className="form-control mt-1"
                      value={windshieldRepairProfileId || ''}
                      onChange={(event) => setWindshieldRepairProfileId(event.target.value || null)}
                      name="windshieldRepairProfileId"
                      id="windshieldRepairProfileId"
                      disabled={!values.windshieldRepairReplaceDecisionEnabled}
                    >
                      <option key="defaultWindshieldRepairProfileId" value="">-</option>
                      {
                        windshieldRepairProfiles?.results?.map((x) => <option key={x.id} value={x.id}>{x.name}</option>)
                      }
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-10 col-sm-12">
                    <Field
                      type="text"
                      name="windshieldRepairReplaceBookingUrl"
                      label="Repair booking url"
                      touched={touched.windshieldRepairReplaceBookingUrl}
                      errors={errors.windshieldRepairReplaceBookingUrl}
                      disabled={!values.windshieldRepairReplaceDecisionEnabled}
                    />
                    {status && <Error>{status}</Error>}
                  </div>
                </div>

                {
                  enabledFeatures.damageDetectionEnabled && (
                    <>
                      <h6 className="mt-2">Automatic damage detection settings</h6>

                      <div className="row">
                        <div className="col-10 col-sm-12">
                          <div className="form-check">
                            <FormikField
                              name="damageDetectionEnabled"
                              className="form-check-input mr-2"
                              type="checkbox"
                            />
                            <label className="form-check-label" htmlFor="damageDetectionEnabled">Enabled</label>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }

                <h6 className="mt-2">Manual damage labelling settings</h6>

                <div className="row">
                  <div className="col-10 col-sm-12">
                    <div className="form-check">
                      <FormikField
                        name="manualDamageLabellingEnabled"
                        className="form-check-input mr-2"
                        type="checkbox"
                      />
                      <label className="form-check-label" htmlFor="manualDamageLabellingEnabled">Enabled</label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-10 col-sm-12 pl-5">
                    <div className="form-check">
                      <FormikField
                        name="manualDamageLabellingConfirmNoDamages"
                        className="form-check-input mr-2"
                        type="checkbox"
                        disabled={!values.manualDamageLabellingEnabled}
                      />
                      <label className="form-check-label" htmlFor="manualDamageLabellingConfirmNoDamages">Ask confirmation on "no damages"</label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-10 col-sm-12 pl-5">
                    <div className="form-check">
                      <FormikField
                        name="manualDamageLabellingCustomDamagesEnabled"
                        className="form-check-input mr-2"
                        type="checkbox"
                        disabled={!values.manualDamageLabellingEnabled}
                      />
                      <label className="form-check-label" htmlFor="manualDamageLabellingCustomDamagesEnabled">Use personalized damages</label>
                    </div>
                  </div>
                </div>

                <h6 className="mt-2">Location settings</h6>

                <div className="row">
                  <div className="col-10 col-sm-12">
                    <div className="form-check">
                      <FormikField
                        name="trackGeolocationEnabled"
                        className="form-check-input mr-2"
                        type="checkbox"
                      />
                      <label className="form-check-label" htmlFor="trackGeolocationEnabled">Save GPS location</label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-10 col-sm-12 pl-5">
                    <div className="form-check">
                      <FormikField
                        className="form-check-input"
                        type="radio"
                        name="trackGeolocationRequired"
                        id="trackGeolocationRequiredHidden"
                        disabled={!values.trackGeolocationEnabled}
                        value="false"
                        checked={!values.trackGeolocationRequired}
                        onChange={() => {
                          setFieldValue('trackGeolocationRequired', false);
                        }}
                      />
                      <label className="form-check-label" htmlFor="trackGeolocationRequiredHidden">
                        Hidden
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-10 col-sm-12 pl-5">
                    <div className="form-check">
                      <FormikField
                        className="form-check-input"
                        type="radio"
                        name="trackGeolocationRequired"
                        id="trackGeolocationRequiredBlocker"
                        disabled={!values.trackGeolocationEnabled}
                        value="true"
                        checked={values.trackGeolocationRequired}
                        onChange={() => {
                          setFieldValue('trackGeolocationRequired', true);
                        }}
                      />
                      <label className="form-check-label" htmlFor="trackGeolocationRequiredBlocker">
                        Blocker
                      </label>
                    </div>
                  </div>
                </div>

                {
                  customerConfiguration?.expectedCountryValidationEnabled && (
                    <>
                      <div className="row">
                        <div className="col-10 col-sm-12">
                          <label htmlFor="expectedCountryId" className="mb-0 mt-1">
                            Expected country
                          </label>
                          <select
                            className="form-control mt-1"
                            value={expectedCountryId || ''}
                            onChange={(event) => setExpectedCountryId(event.target.value || null)}
                            name="expectedCountryId"
                            id="expectedCountryId"
                            disabled={!values.trackGeolocationRequired}
                          >
                            <option key="defaultExpectedCountryId" value="">-</option>
                            {
                              countries.map((country) => <option key={country.id} value={country.id}>{country.name}</option>)
                            }
                          </select>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-10 col-sm-12 pl-5 mt-1">
                          <div className="form-check">
                            <FormikField
                              className="form-check-input"
                              type="radio"
                              name="expectedCountryGeolocationRequired"
                              id="expectedCountryGeolocationRequiredHidden"
                              disabled={expectedCountryId == null || !values.trackGeolocationRequired}
                              value="false"
                              checked={!values.expectedCountryGeolocationRequired}
                              onChange={() => {
                                setFieldValue('expectedCountryGeolocationRequired', false);
                              }}
                            />
                            <label className="form-check-label" htmlFor="expectedCountryGeolocationRequiredHidden">
                              Hidden
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-10 col-sm-12 pl-5">
                          <div className="form-check">
                            <FormikField
                              className="form-check-input"
                              type="radio"
                              name="expectedCountryGeolocationRequired"
                              id="expectedCountryGeolocationRequiredBlocker"
                              disabled={expectedCountryId == null || !values.trackGeolocationRequired}
                              value="true"
                              checked={values.expectedCountryGeolocationRequired}
                              onChange={() => {
                                setFieldValue('expectedCountryGeolocationRequired', true);
                              }}
                            />
                            <label className="form-check-label" htmlFor="expectedCountryGeolocationRequiredBlocker">
                              Blocker
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }

                <h6 className="mt-3">Other settings</h6>

                <div className="row">
                  <div className="col-10 col-sm-12">
                    <div className="form-check">
                      <FormikField
                        name="timeLimitValidationEnabled"
                        className="form-check-input mr-2"
                        type="checkbox"
                      />
                      <label className="form-check-label" htmlFor="timeLimitValidationEnabled">Time limit validation</label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-10 col-sm-12">
                    <div className="form-check">
                      <FormikField
                        name="publicPhotoSeries"
                        className="form-check-input mr-2"
                        type="checkbox"
                      />
                      <label className="form-check-label" htmlFor="publicPhotoSeries">Public photo series</label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-10 col-sm-12">
                    <div className="form-check">
                      <FormikField
                        name="smartScanImageDownloadEnabled"
                        className="form-check-input mr-2"
                        type="checkbox"
                      />
                      <label className="form-check-label" htmlFor="smartScanImageDownloadEnabled">Smart Scan image download enabled</label>
                    </div>
                  </div>
                </div>

                {
                  hasPermission(EDIT_WORKFLOW_USER_FEEDBACK_SETTINGS) && (
                    <>
                      <h6 className="mt-3">User feedback settings</h6>

                      <div className="row">
                        <div className="col-10 col-sm-12">
                          <div className="form-check">
                            <FormikField
                              name="userFeedbackSettings.satisfactionRatingEnabled"
                              className="form-check-input mr-2"
                              type="checkbox"
                            />
                            <label className="form-check-label" htmlFor="satisfactionRatingEnabled">
                              Satisfaction rating enabled
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-10 col-sm-12">
                          <div className="form-check">
                            <FormikField
                              name="userFeedbackSettings.commentEnabled"
                              className="form-check-input mr-2"
                              type="checkbox"
                            />
                            <label className="form-check-label" htmlFor="commentEnabled">
                              Comment enabled
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }

                <div className="row">
                  <div className="col-10 col-sm-12 pt-3 text-right">
                    <button
                      type="button"
                      className="btn btn-secondary mr-1"
                      disabled={isSubmitting}
                      onClick={() => {
                        onHideHandler(hasUnsavedChanges, hideModal, setShowConfirmModal);
                      }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={hasReachedWorkflowsLimit || isSubmitting}>
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

WorkflowModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  workflow: PropTypes.object,
  hasReachedWorkflowsLimit: PropTypes.bool,
  show: PropTypes.bool
};

export default WorkflowModal;
