import { parseTypes } from '../../utils/constants';

const NAME = 'vehicles';

const FILTER = {
  page: {
    name: 'page',
    parseType: parseTypes.number,
  },
  pageSize: {
    name: 'pageSize',
    parseType: parseTypes.number,
  },
  searchPhrase: {
    name: 'searchPhrase',
    parseType: parseTypes.string,
  },
  completedOnOrAfter: {
    name: 'completedOnOrAfter',
    parseType: parseTypes.date,
  },
  completedOnOrBefore: {
    name: 'completedOnOrBefore',
    parseType: parseTypes.date,
  },
  workflowIds: {
    name: 'workflowIds',
    parseType: parseTypes.stringArray,
  },
  feedbackRatings: {
    name: 'feedbackRatings',
    parseType: parseTypes.numberArray,
  },
  photoSeriesStatuses: {
    name: 'photoSeriesStatuses',
    parseType: parseTypes.numberArray,
  },
  photoSeriesQualityRatings: {
    name: 'photoSeriesQualityRatings',
    parseType: parseTypes.numberArray,
  },
  foundImageErrors: {
    name: 'foundImageErrors',
    parseType: parseTypes.numberArray,
  },
  externalId: {
    name: 'externalId',
    parseType: parseTypes.string,
  },
  hasImages: {
    name: 'hasImages',
    parseType: parseTypes.boolean,
  },
  hasFeedbackComment: {
    name: 'hasFeedbackComment',
    parseType: parseTypes.boolean,
  },
  damages: {
    name: 'damages',
    parseType: parseTypes.numberArray
  },
  hasAttemptedImages: {
    name: 'hasAttemptedImages',
    parseType: parseTypes.boolean
  },
  sharedWithMe: {
    name: 'sharedWithMe',
    parseType: parseTypes.boolean
  },
  sharedWorkflowIds: {
    name: 'sharedWorkflowIds',
    parseType: parseTypes.stringArray
  },
  sharedCustomerIds: {
    name: 'sharedCustomerIds',
    parseType: parseTypes.stringArray
  },
};

const DEFAULT_SORT = {
  page: 1,
  pageSize: 20,
};

export default {
  NAME,
  FILTER,
  DEFAULT_SORT,
};
