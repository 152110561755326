import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '../../../../../components';
import { useCheckbox } from '../../../../../components/Checkbox/hooks/useCheckbox';

const HasImagesCheckbox = ({ hasImages, setHasImages }) => {
  const { state, dispatchCheckYes, dispatchCheckNo } = useCheckbox();

  return (
    <div className="has-images-checbox-container">
      <label htmlFor="hasImages" className="mb-0 mt-1">Has images</label>
      <div className="mt-2 d-flex">
        <Checkbox
          id="yesHasImages"
          label="Yes"
          name="yesHasImages"
          checked={hasImages === true ? true : state.yes}
          onClickHandler={({ value }) => {
            dispatchCheckYes();

            if (!value) setHasImages(true);
            else setHasImages(null);
          }}
        />
        <Checkbox
          className="ml-4"
          id="noHasImages"
          label="No"
          name="noHasImages"
          checked={hasImages === false ? true : state.no}
          onClickHandler={({ value }) => {
            dispatchCheckNo();

            if (!value) setHasImages(false);
            else setHasImages(null);
          }}
        />
      </div>
    </div>
  );
};

HasImagesCheckbox.propTypes = {
  hasImages: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setHasImages: PropTypes.func.isRequired
};
export default HasImagesCheckbox;
