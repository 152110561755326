import React from 'react';
import { Tabs } from '../../../../components';
import { useVehicleFilterContext } from '../../Vehicles';

const VehiclesQuickFilter = () => {
  const All = 'All';
  const MyVehicles = 'My vehicles';
  const SharedWithMe = 'Shared with me';

  const [vehiclesFilter, setVehiclesFilter] = useVehicleFilterContext();

  const getActiveTab = () => {
    const { sharedWithMe } = vehiclesFilter;

    if (sharedWithMe === true) return SharedWithMe;
    if (sharedWithMe === false) return MyVehicles;
    return All;
  };

  const setFilter = (key, value) => {
    const filter = { ...vehiclesFilter };
    filter[key] = value;
    setVehiclesFilter(filter);
  };

  const unsetFilter = (key) => {
    const filter = { ...vehiclesFilter };
    delete filter[key];
    setVehiclesFilter(filter);
  };

  const handleTabClick = (name) => {
    switch (name) {
      case All:
        unsetFilter('sharedWithMe');
        break;
      case MyVehicles:
        setFilter('sharedWithMe', false);
        break;
      case SharedWithMe:
        setFilter('sharedWithMe', true);
        break;
      default:
    }
  };

  return <Tabs tabs={[All, MyVehicles, SharedWithMe]} activeTab={getActiveTab()} onTabClick={(name) => handleTabClick(name)} />;
};

export default VehiclesQuickFilter;
