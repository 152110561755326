import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import { MenuPage } from '../../layouts';
import { PageTitle, Button, Badge } from '../../components';
import {
  actions as vehiclesActions,
  selectors as vehiclesSelectors,
  constants as vehiclesConstants,
  helpers as vehiclesHelpers,
} from '../../redux/vehicles';
import { actions as workflowsActions, selectors as workflowsSelectors } from '../../redux/workflows';
import { useDidMount } from '../../hooks';
import { routes } from '../../routes/Routes';
import { makeGet } from '../../api/ajax';
import { parseSearchQuery } from '../../utils/url';

import VehiclesTable from './containers/VehiclesTable/VehiclesTable';
import Pagination from './containers/VehiclesTable/containers/Pagination';
import PublicLinkGeneratorModal from './containers/PublicLinkGeneratorModal/PublicLinkGeneratorModal';
import VehiclesFilter from './containers/VehiclesFilter/VehiclesFilter';
import VehiclesFilterTags from './containers/VehiclesFilterTags/VehiclesFilterTags';
import VehiclesQuickFilter from './containers/VehiclesQuickFilter/VehiclesQuickFilter';

const FilterContext = React.createContext();

export const useVehicleFilterContext = () => useContext(FilterContext);

const Vehicles = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [vehicleFilter, setVehicleFilter] = useState(() =>
    parseSearchQuery(location.search, vehiclesConstants.FILTER, vehiclesConstants.DEFAULT_SORT));

  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showLinkGeneratorModal, setShowLinkGeneratorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const pagedResult = useSelector(vehiclesSelectors.selectPagedResult) || {};
  const hasWorkflows = useSelector(workflowsSelectors.hasWorkflows);

  const {
    loaded: vehiclesLoaded,
    filter: pagedResultFilter,
  } = pagedResult;

  const apiSearchVehicles = useCallback(
    (filter) => {
      const url = `vehicles?${queryString.stringify(filter)}`;

      history.replace(url);

      setIsLoading(true);

      return makeGet(url).subscribe(({ response }) => {
        dispatch(vehiclesActions.searchVehiclesSuccess(response, filter));
        setIsLoading(false);
      });
    },
    [dispatch, history, setIsLoading],
  );

  useDidMount(() => {
    if (hasWorkflows) return;
    makeGet('workflows/currentCustomer/admin?showDeleted=true').subscribe(({ response }) => {
      dispatch(workflowsActions.loadWorkflowsSuccess(response));
    });
  });

  useEffect(() => {
    if (vehiclesLoaded && vehiclesHelpers.filtersAreEqual(vehicleFilter, pagedResultFilter)) return;

    setSelectedVehicle(null);

    apiSearchVehicles(vehicleFilter);
  }, [vehicleFilter, apiSearchVehicles, vehiclesLoaded, pagedResultFilter]);

  const onPageChange = (page) => {
    setVehicleFilter({
      ...vehicleFilter,
      page,
    });
  };

  const toggleShowLinkGeneratorModal = () => {
    setShowLinkGeneratorModal(!showLinkGeneratorModal);
  };

  const { page, pageCount, rowCount } = pagedResult;

  const handleVehicleRowClick = (selectedVehicle) => {
    if (isLoading) return;
    setSelectedVehicle(selectedVehicle);
  };

  return (
    <FilterContext.Provider value={[vehicleFilter, setVehicleFilter]}>
      <MenuPage currentRoute={routes.vehicles}>
        {showLinkGeneratorModal && (
          <PublicLinkGeneratorModal
            id="vehicles-link-generator-modal"
            show={showLinkGeneratorModal}
            onHide={() => setShowLinkGeneratorModal(false)}
          />
        )}
        <PageTitle>
          <PageTitle.Left headingText="Vehicles" isLoading={isLoading}>
            {
              !isLoading && (
                <Badge className="ml-2">
                  { pagedResult?.rowCount !== undefined ? new Intl.NumberFormat().format(rowCount) : 0 }
                </Badge>
              )
            }
          </PageTitle.Left>

          <PageTitle.Right>
            <VehiclesFilter />
            <Button testId="VehiclesNewVehicleReqBtn" className="btn btn-primary" onClick={toggleShowLinkGeneratorModal}>
              New vehicle request
            </Button>
          </PageTitle.Right>
        </PageTitle>

        <div className="row">
          <div className="col-sm-12 mb-1">
            <VehiclesQuickFilter />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <VehiclesFilterTags />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className={`row no-gutters ${isLoading ? 'filter-loading' : ''}`}>
              <div className="col-sm-12">
                <VehiclesTable onSelectVehicle={handleVehicleRowClick} selectedVehicle={selectedVehicle} isLoadingTableData={isLoading} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <Pagination resultCount={rowCount} currentPage={page} pageCount={pageCount} onPageChange={onPageChange} />
          </div>
        </div>
      </MenuPage>
    </FilterContext.Provider>
  );
};

export default Vehicles;
