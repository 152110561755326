import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '../../../../../components';
import { useCheckbox } from '../../../../../components/Checkbox/hooks/useCheckbox';

const HasAttemptedImages = ({ hasAttemptedImages, setHasAttemptedImages }) => {
  const { state, dispatchCheckYes, dispatchCheckNo } = useCheckbox();

  return (
    <div className="has-attempted-images-checbox-container">
      <label htmlFor="hasAttemptedImages" className="mb-0 mt-1">Has attempted images</label>

      <div className="mt-2 d-flex">
        <Checkbox
          id="yesHasAttemptedImages"
          label="Yes"
          name="yesHasAttemptedImages"
          checked={hasAttemptedImages === true ? true : state.yes}
          onClickHandler={({ value }) => {
            dispatchCheckYes();

            if (!value) setHasAttemptedImages(true);
            else setHasAttemptedImages(null);
          }}
        />
        <Checkbox
          className="ml-4"
          id="noHasAttemptedImages"
          label="No"
          name="noHasAttemptedImages"
          checked={hasAttemptedImages === false ? true : state.no}
          onClickHandler={({ value }) => {
            dispatchCheckNo();

            if (!value) setHasAttemptedImages(false);
            else setHasAttemptedImages(null);
          }}
        />
      </div>
    </div>
  );
};

HasAttemptedImages.propTypes = {
  hasAttemptedImages: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setHasAttemptedImages: PropTypes.func.isRequired
};
export default HasAttemptedImages;
